import React from "react"

import GitHub from "../images/skills/github.svg"
import JavaScript from "../images/skills/javascript.svg"
import ReactJS from "../images/skills/react.svg"
import HTML5 from "../images/skills/html5.svg"
import CSS3 from "../images/skills/css3.svg"
import Sass from "../images/skills/sass.svg"
import Bootstrap from "../images/skills/bootstrap.svg"
import WordPress from "../images/skills/wordpress-ar21.svg"
import Laravel from "../images/skills/laravel-ar21.svg"
import Java from "../images/skills/java-horizontal.svg"
import Expo from "../images/skills/expoio-ar21.svg"
import Node from "../images/skills/nodejs.svg"
import GraphQl from "../images/skills/GraphQL_Logo.svg"
import Docker from "../images/skills/docker-official.svg"
import Mongo from "../images/skills/mongodb-ar21.svg"
import Android from "../images/skills/android-icon.svg"
import Php from "../images/skills/php-ar21.svg"
import Linux from "../images/skills/linux-icon.svg"
import Puppeteer from "../images/skills/puppeteer.svg"
import Linode from "../images/skills/linode-ar21.svg"
import TailWind from "../images/skills/tailwindcss-ar21.svg"
import MySql from "../images/skills/mysql-ar21.svg"
import Postgress from "../images/skills/postgresql-ar21.svg"
const Skills = () => {
  const logos = [
    HTML5,
    CSS3,
    JavaScript,
    ReactJS,
    Sass,
    Bootstrap,
    GitHub,
    WordPress,
    Laravel,
    Java,
    Expo,
    Node,
    GraphQl,
    MySql,
    Postgress,
    Docker,
    Mongo,
    Android,
    Php,
    Linux,
    TailWind,
    Puppeteer,
    Linode
  ]
  return (
    <section className="skills" data-aos="fade-right">
      <h2>Skills</h2>
      <div className="skills-flex-container">
        {logos.map((value, index) => {
          return <Skill key={index} skill={value} />
        })}
      </div>
    </section>
  )
}

const Skill = ({ skill }) => (
  <img className="skill-logo skill-transition" src={skill} alt="" />
)

export default Skills
