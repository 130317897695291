import React from "react"
import coder from "../svgs/desk_coder.svg"

const About = () => (
  <section id="about">
    <div className="about-container">
      <div className="coder-img">
        <img src={coder} alt="Coder Illustration" />
      </div>
      <div className="profile">
        <h1>Hi,</h1>
        <p>
          I'm Hasan Awwad, Web and Mobile Developer. I have been studying and
          working in the tech field for over 5 years, and plan on staying in
          tune with the latest technologies through continued coursework and
          professional development. I am fueled by my passion for programming,
          problem solving and security. I am currently working as a full-time senior web and mobile developer and tech mentor.  I am always interested in
          challenges. Feel free to reach out to me by email at info@hasanawwad.com to connect and know more about my work!
        </p>
      </div>
    </div>
  </section>
)

export default About
